
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="card text-left">
      <div class="card-body">
        <b-tabs
          active-nav-item-class="nav nav-tabs"
          content-class="mt-3"
        >
          <b-tab title="Inventory" active>

            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-item-group >Add new</b-button>
              </div>
            </div>

            <br/>

            <b-table striped hover :items="inventories" :fields="inventory_columns">

              <template v-slot:cell(itemName)="data">
                <a :href="data.item.id" style="text-decoration: underline;">{{ data.item.itemName  }}</a>
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openEditInventoryItemModal(data.item)">Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openEditProductModal(data.item)">Receive Consignment</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteProductModal(data.item)">Delete</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </b-tab>
          <b-tab title="Products" >

            <div class="row">
               <div class="col-md-12">
                  <b-button class="btn btn-danger float-right" v-b-modal.add-product>Add new</b-button>
               </div>
            </div>

            <br/>

            <b-table striped hover :items="products" :fields="product_columns">
              <template v-slot:cell(productFinish)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <span>{{ data.value.finishName }}</span>

              </template>
              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openEditProductModal(data.item)">Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteProductModal(data.item)">Delete</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </b-tab>
          <b-tab title="Price List" >

            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-product-list>Add new</b-button>
              </div>
            </div>

            <br/>

            <b-table striped hover :items="price_list" :fields="price_list_columns">
              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openEditPriceListModal(data.item)">Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteProductModal(data.item)">Delete</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </div>

      <b-modal id="add-item-group" title="Add Inventory" @cancel="true" @ok="validateBeforeSubmitItemGroup"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Name:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="itemGroup.itemName" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Opening Length:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="itemGroup.openingLength" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Opening Weight:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="itemGroup.openingWeight" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Reorder Level:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="itemGroup.reorderLevel" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Products:</label> <br/>
                  <div >
                    <multiselect v-model="itemGroup.inventoryProducts"  placeholder="Search Name" label="productName" track-by="id" :options="products" :multiple="true" :taggable="true" ></multiselect>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </form>
      </b-modal>

      <b-modal id="edit-item-group" title="Edit Inventory" @cancel="true" @ok="validateBeforeSubmitEditItemGroup"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Name:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="itemGroup.itemName" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Opening Length:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="itemGroup.openingLength" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Opening Weight:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="itemGroup.openingWeight" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Reorder Level:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="itemGroup.reorderLevel" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Products:</label> <br/>
                  <div >
                    <multiselect v-model="itemGroup.inventoryProducts"  placeholder="Search Name" label="productName" track-by="id" :options="products" :multiple="true" :taggable="true" ></multiselect>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </form>
      </b-modal>

      <b-modal id="add-product" title="Add Product" @cancel="true" @ok="validateBeforeSubmitNewProduct"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Product Type:</label> <br/>
                  <div >
                    <select class="form-control" v-model="product.productType">
                      <option value="IRON_SHEET">Iron Sheet</option>
                      <option value="GENERAL">General</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Name:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="product.productName" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="product.productType == 'IRON_SHEET'">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Gauge:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="product.gauge" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Price: (Unit landing Cost )</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="product.price" />
                  </div>
                </div>
              </div>
            </div>


            <div class="row" v-if="product.productType == 'IRON_SHEET'">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Finishes:</label> <br/>
                  <div >
                    <select class="form-control" v-model="product.finishId">
                      <option value="0"> -- Select -- </option>
                      <option :value="productFinish.id" v-for="productFinish in productFinishes">{{ productFinish.finishName }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">QB Inventory ID</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="product.qbInventoryItemId" />
                  </div>
                </div>
              </div>
            </div>

          </div>

        </form>
      </b-modal>

      <b-modal id="add-product-list" title="Add Product Price List" @cancel="true" @ok="validateBeforeSubmitNewProductPriceList"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">County:</label> <br/>
                  <div >
                    <select class="form-control" v-model="priceList.county">
                      <option :value="county.name" v-for="county in counties">{{ county.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Product:</label> <br/>
                  <div >
                    <select class="form-control" v-model="priceList.productId">
                      <option :value="product.id" v-for="product in products">{{ product.productName }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Unit Price:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="priceList.price" />
                  </div>
                </div>
              </div>
            </div>




          </div>

        </form>
      </b-modal>

      <b-modal id="edit-product-list" title="Edit Product Price List" @cancel="true" @ok="validateBeforeSubmitEditProductPriceList"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">County:</label> <br/>
                  <div >
                    <select class="form-control" v-model="priceList.county">
                      <option :value="county.name" v-for="county in counties">{{ county.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Product:</label> <br/>
                  <div >
                    <select class="form-control" v-model="priceList.productId">
                      <option :value="product.id" v-for="product in products">{{ product.productName }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Unit Price:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="priceList.price" />
                  </div>
                </div>
              </div>
            </div>




          </div>

        </form>
      </b-modal>

      <b-modal id="edit-product" title="Edit Product" @cancel="true" @ok="validateBeforeSubmitEditProduct"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Product Type:</label> <br/>
                  <div >
                    <select class="form-control" v-model="product.productType">
                      <option value="IRON_SHEET">Iron Sheet</option>
                      <option value="GENERAL">General</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Name:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="product.productName" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="product.productType == 'IRON_SHEET'">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Gauge:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="product.gauge" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Price:</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="product.price" />
                  </div>
                </div>
              </div>
            </div>

            <!--<div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label "> Unit Cost(Price per meter / Price per Kg):</label> <br/>
                  <div >
                    <input class="form-control" type="number"  v-model="product.pricePerMetre" />
                  </div>
                </div>
              </div>
            </div>-->

            <div class="row" v-if="product.productType == 'IRON_SHEET'">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Finishes:</label> <br/>
                  <div >
                    <select class="form-control" v-model="product.finishId">
                      <option value="0"> -- Select -- </option>
                      <option :value="productFinish.id" v-for="productFinish in productFinishes">{{ productFinish.finishName }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">QB Inventory ID</label> <br/>
                  <div >
                    <input readonly class="form-control" type="number"  v-model="product.qbInventoryItemId" />
                  </div>
                </div>
              </div>
            </div>

          </div>

        </form>
      </b-modal>
    </div>



  </div>
</template>
<script>

  import {counties} from "@/data/counties";

  import Multiselect from 'vue-multiselect'

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapGetters, mapActions } from "vuex";

export default {
  display: "Transitions",
  order: 7,

  data() {
    return {
      counties,
      isLoading: false,
      fullPage: true,
      selected:[],
      products:[],
      price_list:[],
      productFinishes:[],
      inventories:[],
      inventory_columns: ["itemName",{ key: 'openingLength', label: 'Opening Stock' },"availableStock","reorderLevel","dateAdded","options"],
      product_columns: ["id","productName","gauge","productFinish","price","qbInventoryItemId","options"],
      price_list_columns: ["id","county","product","price","options"],
      product:{
        id:0,
        productName:"",
        gauge:0,
        finishId:0,
        price:"",
        pricePerMetre:"",
        productType:"",
        qbInventoryItemId:""
      },
      itemGroup:{
        id:0,
        itemName:"",
        openingLength:"",
        reorderLevel:"",
        inventoryProducts:[],
        openingWeight:""
      },
      priceList:{
        id:0,
        county:"",
        price:"",
        productId:""
      }
    };
  },
  components: {
    Loading,
    Multiselect
  },
  computed: {
    ...mapGetters(["loading","error"])
  },

  mounted (){
    this.getProductsData();
    this.getProductFinishesData();
    this.getItemGroupsData();
    this.getProductsPriceListData();

  },
  methods:{
    ...mapActions(["saveProductPriceList","editProductPriceList","getProductPriceList","editProduct","saveProduct","deleteProduct","getProducts","getProductFinishes","getInventoryItems","saveItemGroup","editItemGroup"]),

    openEditInventoryItemModal(itemGroup){


      this.itemGroup = itemGroup;


      this.$bvModal.show('edit-item-group');

    },

    openEditPriceListModal(priceList){

      console.log(priceList);


      this.priceList = priceList;

      delete this.priceList.product;


      this.$bvModal.show('edit-product-list');

    },

    openEditProductModal(product){



      this.product = product;

      if(product.productFinish){
        this.product.finishId = product.productFinish.id;
      }
      else{
        this.product.finishId = 0;
      }

      delete this.product.productFinish;

      this.$bvModal.show('edit-product');

    },

    getProductFinishesData(){
      let self = this;
      this.getProductFinishes()
              .then(function (productFinishes) {
                self.productFinishes = productFinishes;

              })
              .catch(function (error) {



              })
    },

    getProductsPriceListData(){
      let self = this;
      this.getProductPriceList()
              .then(function (price_list) {

                console.log(price_list);

                self.price_list = price_list;

              })
              .catch(function (error) {



              })
    },

    getProductsData(){
      let self = this;
      this.getProducts()
        .then(function (products) {
          self.products = products;

        })
        .catch(function (error) {



        })
    },

    getItemGroupsData(){
      let self = this;
      this.getInventoryItems()
              .then(function (inventories) {
                self.inventories = inventories;

              })
              .catch(function (error) {



              })
    },

    validateBeforeSubmitEditProduct(){
      this.isLoading = true;

      let self = this;

      this.editProduct(this.product).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProductsData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });


        })
    },

    validateBeforeSubmitEditItemGroup(){
      this.isLoading = true;

      let self = this;

      this.itemGroup.inventoryProducts = this.itemGroup.inventoryProducts.map(function (inventoryProduct) {
        return inventoryProduct.id;
      });


      this.editItemGroup(this.itemGroup).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Item group saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getItemGroupsData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },

    validateBeforeSubmitItemGroup(){
      this.isLoading = true;

      let self = this;

      this.itemGroup.inventoryProducts = this.itemGroup.inventoryProducts.map(function (inventoryProduct) {
          return inventoryProduct.id;
      });

      console.log(this.itemGroup);

      this.saveItemGroup(this.itemGroup).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Item group saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getItemGroupsData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },

    validateBeforeSubmitEditProductPriceList(){
      this.isLoading = true;

      let self = this;


      this.editProductPriceList(this.priceList).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product price list saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProductsPriceListData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },

    validateBeforeSubmitNewProductPriceList(){
      this.isLoading = true;

      let self = this;


      this.saveProductPriceList(this.priceList).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product price list saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProductsPriceListData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },

    validateBeforeSubmitNewProduct(){
      this.isLoading = true;

      let self = this;

      this.saveProduct(this.product).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProductsData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });


        })
    },


    confirmDelete(){
      this.isLoading = true;

      let self = this;

      this.deleteProduct(this.product).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProductsData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/app/sessions/signIn");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    openDeleteProductModal(product){

      this.product = product;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.product.productName +' from system')
              .then(value => {

                if(value){
                  self.confirmDelete();
                }

              })
              .catch(err => {
                // An error occurred
              })
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
